import { get, post } from '@/utils/request'

const contactApi = {
  applyContactApi: '/api/user/contact/apply/records',
  applyContactCountApi: '/api/user/contact/apply/unread',
  acceptApplyContactApi: '/api/user/contact/apply/accept',
  declineApplyContactApi: '/api/user/contact/apply/decline',
  getContactApi: '/api/user/contact/list',
  addContactApi: '/api/user/contact/apply/create',
  searchContactApi: '/api/user/contact/search',
  getContactDetailApi: '/api/user/contact/detail',
  contactReplyApi: '/api/user/contact/reply',
  updateContactRemarkApi: '/api/user/contact/remark'
}

export function getApplyRecords(pageNumber) {
  return get(contactApi.applyContactApi + '?pageNumber=' + pageNumber)
}

export function getUserContact() {
  return get(contactApi.getContactApi)
}

export function getApplyContactCount() {
  return get(contactApi.applyContactCountApi)
}

export function searchUserContact(userId) {
  return get(contactApi.searchContactApi, userId)
}

export function addUserContact(addForm) {
  return post(contactApi.addContactApi, addForm)
}

export function approveUserContact(payload) {
  return post(contactApi.acceptApplyContactApi, payload)
}

export function declineUserContact(payload) {
  return post(contactApi.declineApplyContactApi, payload)
}

export function getContactDetail(userId) {
  return get(contactApi.getContactDetailApi + '/' + userId)
}

export function contactReply(payload) {
  return post(contactApi.contactReplyApi, payload)
}

export function updateContactRemark(payload) {
  return post(contactApi.updateContactRemarkApi, payload)
}
