<template>
  <el-row class="movie-list">
    <el-col :md="18">
      <div class="movie-list" style="height: 70vh;">
        <el-button
          size="mini"
          type="submit"
          class="el-icon-delete"
          @click="onClear"
        >清空</el-button>
        <!-- 注意需要给 el-scrollbar 设置高度，判断是否滚动是看它的height判断的 -->
        <el-scrollbar ref="myScrollbar" style="width: 100%; height: 100%; background-color: #B3C0D1">
          <el-row class="movie-list">
            <div
              v-for="(item, index) in talkItems"
              :key="index"
              :md="6"
              :sm="12"
              :xs="12"
            >
              <el-row
                style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px"
                type="flex"
                class="row-bg"
                :justify="item.justify"
              >
                <el-col :md="12">
                  <el-card class="box-card">
                    <el-row>
                      <div style="padding: 14px">
                        <span style="right: 0;margin-bottom: 0px;color: black;">{{ item.content }}</span>
                      </div>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </el-row>
        </el-scrollbar>
      </div>
    </el-col>
    <el-col :md="6">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <span style="color: forestgreen">{{socketStatus}}</span>
<!--        <el-col>
          <el-button
            size="mini"
            type="danger"
            class="el-icon-delete"
            @click="clear"
          >清空</el-button>
        </el-col>-->
        <el-select v-model="receiverId" placeholder="选择联系人">
          <el-option
            v-for="(item, index) in contactList"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-row>
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <el-input
            v-model="text"
            type="textarea"
            :rows="3"
            placeholder="有什么新鲜事想分享给大家?"
            @keydown.enter.native="keyDown"
          />
          <el-row style="text-align: right">
            <el-button
              size="mini"
              type="submit"
              class="el-icon-delete"
              @click="clear"
            >清空</el-button>
            <el-button
              size="mini"
              type="submit"
              class="el-icon-message"
              @click="onSubmit"
            >发送</el-button>
          </el-row>
        </el-card>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getAccessToken, getAuthedUser } from '@/utils/auth'
import { getUserContact } from '@/api/contact'
import SocketInstance from '@/utils/ws/socket-instance'
import store from '@/store'

export default {
  name: 'Chat',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 50
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  data() {
    return {
      wsClient: null,
      // 最后心跳时间
      lastTime: 0,
      user: null,
      contactList: [],
      text: '',
      receiverId: null,
      websocket: null,
      token: null,
      online: false
    }
  },
  computed: {
    ...mapState({
      socketStatus: state => state.socketStatus
    }),
    ...mapGetters(['talkItems'])
  },
  mounted() {
    const token = getAccessToken()
    if (token === null) {
      this.$message.error('当前未登入')
      return
    } else {
      this.token = token
    }

    const wsUrl = 'ws:' + process.env.VUE_APP_SERVER_URL + '/ws/chat?token=' + this.token
    this.wsClient = new SocketInstance(wsUrl)
    this.wsClient.connect()
  },
  created() {
    document.title = 'Chat'
    const userInfo = getAuthedUser()
    if (userInfo === null) {
      this.$message.error('当前未登入')
      return
    }
    this.user = userInfo
    getUserContact().then(resp => {
      if (resp.code === 0) {
        for (const item of resp.data) {
          var option = {}
          option.label = item.nickname
          option.value = item.id
          this.contactList.push(option)
        }
      }
    })

    const token = getAccessToken()
    if (token === null) {
      return
    } else {
      this.token = token
    }
  },
  methods: {
    keyDown(e) {
      if (e.ctrlKey && e.keyCode === 13) { // 用户点击了ctrl+enter触发
        this.text += '\n'
      } else {
        // 用户点击了enter触发
        // 执行一些逻辑方法
        if (e !== undefined) {
          e.preventDefault()
          this.onSubmit()
        }
      }
    },
    clear() {
      this.text = ''
    },
    onClear() {
      store.commit('REMOVE_TALK_ITEM')
    },
    onSubmit() {
      if (this.receiverId === null) {
        this.$message.error('没有选择联系人')
        return
      }

      var payload = {}
      payload.receiverId = this.receiverId
      payload.senderId = this.user.userId
      payload.content = this.text

      var jsonData = {}
      jsonData.event = 'event_talk'
      jsonData.data = JSON.stringify(payload)

      this.wsClient.send(jsonData)
      this.text = ''

      var justify = 'end'
      /* if (jsonData.data.senderId === this.user.userId) {
        justify = 'start'
      }*/
      var msg = {}
      msg.receiverId = payload.receiverId
      msg.content = payload.content
      var item = {
        content: msg,
        justify: justify
      }

      store.commit('PUSH_TALK_ITEM', item)
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

/*必要的*/
/*隐藏底部滚动条*/
  /deep/ .el-scrollbar__wrap {
    overflow: scroll;
    height: 100% !important;
    overflow-x: hidden !important;
  }
</style>
